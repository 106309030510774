<template>
  <Loading />
</template>

<script>
import Loading from './Loading.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    Loading
  },
  name: 'Logout',
  async created () {
    await this.logoutlocal()
  },
  methods: {
    ...mapMutations(['logout']),
    async logoutlocal () {
      try {
        let route = `/session/logout`
        await this.axios.post(route)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this['logout']()
        this.isLoading = false
        this.$router.push({ name: 'Login' }).then(_ => window.location.reload()).catch(err => { this.handleApiErr(err) })
      }
    }
  }
}
</script>
